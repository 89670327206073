import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { MenuOutlined, CloseOutlined } from '@ant-design/icons';

export const primary = '#F09319';
export const secondary = '#3D5300';

export const HeaderSection = styled('header')`
  width: 100%;
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  position: fixed; /* Makes the header fixed */
  top: 0; /* Ensures it stays at the top of the page */
  left: 0; /* Aligns it to the left edge */
  z-index: 1000; /* Ensures it stays above other elements */

  background-color: #fff; /* Optional: Set background color to prevent transparency */
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1); /* Optional: Add a subtle shadow for distinction */

  .ant-row-space-between {
    align-items: center;
    text-align: center;
  }
`;

export const LogoContainer = styled(Link)`
  display: flex;
`;

export const NavLink = styled(Link)`
  display: inline-block;
  text-align: center;
`;

export const Burger = styled('div')`
  @media only screen and (max-width: 890px) {
    display: block;
  }

  display: none;

  svg {
    fill: #f09319;
  }
`;

export const NotHidden = styled('div')`
  @media only screen and (max-width: 890px) {
    display: none;
  }
`;

export const Menu = styled('h5')`
  font-size: 1.5rem;
  font-weight: 600;
  text-align: center;
`;

export const CustomNavLinkSmall = styled(NavLink)`
  font-size: 1.2rem;
  color: ${primary};
  transition: color 0.2s ease-in;
  margin: 0.5rem 2rem;

  @media only screen and (max-width: 768px) {
    margin: 1.25rem 2rem;
  }
`;

export const Label = styled('span')`
  font-weight: 500;
  color: ${primary};
  text-align: right;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
`;

export const Outline = styled(MenuOutlined)`
  font-size: 22px;
`;
export const CloseOutline = styled(CloseOutlined)`
  font-size: 22px;
`;

export const Span = styled('span')`
  cursor: pointer;
  transition: all 0.3s ease-in-out;

  &:hover,
  &:active,
  &.active,
  &:focus {
    color: ${secondary};
    text-underline-position: under;
    text-decoration: ${secondary} underline;
  }
`;
